import { getFullName } from '@@helpers/getFullName';
import axios from 'axios';
import debounce from 'debounce-promise';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { z } from 'zod';
import { convertDataToOptions } from './helpers/convertDataToOptions';
import { zh } from './helpers/zodHelpers';

import { BaseQueryKey, invalidateQueries } from './helpers/invalidateQueries';
import { regionSchema } from './regionsApi';
import { roleSchema } from './rolesApi';
import { schoolSchema } from './schoolsApi';
import { FindInput, QueryOptions, SearchInput, SearchResult } from './types';

const baseApiPath = '/users';
const baseQueryKey = BaseQueryKey.Users;

/**
 * Staff details
 * (Move into staffDetailsApi.ts if we need to use it elsewhere)
 * Add invalid_type_error to support the loose schema shape below
 */
export const staffDetailInputSchema = z.object({
  firstName: zh.string(50).required(),
  lastName: zh.string(50).required(),
  position: zh.string(50).required(),
  mainSchoolId: zh.intid(),
  mainSchool: schoolSchema.nullish(),
});
export const staffDetailLooseInputSchema = z.object({
  firstName: zh.string().nullish(),
  lastName: zh.string().nullish(),
  position: zh.string().nullish(),
  mainSchoolId: zh.intid().nullish(),
});
export type StaffDetailInput = z.infer<typeof staffDetailInputSchema>;

export const staffDetailSchema = staffDetailInputSchema.extend({
  id: zh.uuid(),
  isDeleted: z.boolean(),
});
export type StaffDetail = z.infer<typeof staffDetailSchema>;

export const caregiverDetailSchema = z.object({
  firstName: zh.string(50).required(),
  lastName: zh.string(50).required(),
  familyId: zh.uuid(),
});
export type CaregiverDetail = z.infer<typeof caregiverDetailSchema>;

/**
 * Users
 */
export const userBaseSchema = z.object({
  email: zh.email().required(),
  isStaff: z.boolean().nullish(),
  isSoftLocked: z.boolean().nullish(),
  isAdminLocked: z.boolean().nullish(),
});
export const userInputSchema = userBaseSchema
  .extend({
    // Start with a forgiving schema shape
    staffDetail: staffDetailLooseInputSchema.nullish(),
    roles: z.array(roleSchema.pick({ id: true })).nullish(),
    password: zh.string(50, 8).nullish(),
    confirmPassword: zh.string(50, 8).nullish(),
  })
  .transform((values) => {
    // Remove staff detail and roles if the user is not staff
    return values.isStaff ? values : _.omit(values, ['staffDetail', 'roles', 'password', 'confirmPassword']);
  })
  .superRefine((values, ctx) => {
    // Staff members have more strict requirements
    if (values.isStaff) {
      // Apply strict validation to staff detail
      const result = staffDetailInputSchema.safeParse(
        _.get(values, 'staffDetail')
      );
      if (!result.success) {
        _.each(result.error.issues, (issue) => {
          ctx.addIssue({
            message: issue.message,
            code: z.ZodIssueCode.custom,
            path: ['staffDetail', ...issue.path],
          });
        });
      }
      // Ensure staff have at least one role selected
      if (_.isEmpty(_.get(values, 'roles'))) {
        ctx.addIssue({
          message: 'At least one role is required',
          code: z.ZodIssueCode.custom,
          path: ['roles'],
        });
      }
      // Ensure if password is set, confirm password is also set and is the same
      const password = _.get(values, 'password');
      const confirmPassword = _.get(values, 'confirmPassword');  
      if (password && password !== confirmPassword) {
        ctx.addIssue({
          message: 'Passwords do not match',
          code: z.ZodIssueCode.custom,
          path: ['confirmPassword'],
        });
      }
    }
  });
export type UserInput = z.infer<typeof userInputSchema>;

/**
 * Note: profilePicture in the User DTO but will be deprecated
 */
export const userSchema = userBaseSchema.extend({
  id: zh.uuid(),
  isDeleted: z.boolean(),
  staffDetail: staffDetailSchema.nullish(),
  caregiverDetail: caregiverDetailSchema.nullish(),
  regions: z.array(regionSchema).nullish(),
  schools: z.array(schoolSchema).nullish(),
  roles: z.array(roleSchema.pick({ id: true })).nullish(),
});
export type User = z.infer<typeof userSchema>;

type UserFindInput = FindInput & {
  includeStaffDetail?: boolean;
  includeRoles?: boolean;
  includeTypeaheads?: boolean;
  includeRegions?: boolean;
  includeSchools?: boolean;
};

export const useUser = (id: string, params: UserFindInput) => {
  return useQuery<User, Error>([baseQueryKey, id, params], () => {
    return axios
      .get<User>(`${baseApiPath}/${id}`, { params })
      .then((res) => res.data);
  });
};

export const useAddUser = () => {
  return useMutation<User, Error, UserInput>(
    (values) => {
      return axios.post<User>(baseApiPath, values).then((res) => res.data);
    },
    {
      onSuccess: () => {
        invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useDeleteUser = () => {
  return useMutation<boolean, Error, number | string>(
    (id) => {
      return axios
        .delete<boolean>(`${baseApiPath}/${id}`)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useRestoreUser = () => {
  return useMutation<User, Error, string | number>(
    (id) => {
      return axios
        .patch<User>(`${baseApiPath}/${id}/restore`)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useUpdateUser = (id: number | string) => {
  return useMutation<User, Error, UserInput>(
    (values) => {
      return axios
        .patch<User>(`${baseApiPath}/${id}`, values)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        invalidateQueries(baseQueryKey);
      },
    }
  );
};

const convertUsersToOptions = (data?: User[]) => {
  return convertDataToOptions(data, {
    labelKey: (row) => {
      return row.staffDetail ? getFullName(row.staffDetail) : '(No name)';
    },
  });
};

const userSearch = (input: SearchInput<User>) => {
  return axios
    .post<SearchResult<User>>(`${baseApiPath}/search`, input)
    .then((res) => res.data);
};

export const loadUserOptions = debounce(async (input: SearchInput<User>) => {
  const result = await userSearch(input);
  return convertUsersToOptions(result.data);
}, 250);

export const useUserSearch = (
  input: SearchInput<User>,
  options?: QueryOptions
) => {
  return useQuery<SearchResult<User>, Error>(
    [baseQueryKey, 'search', input],
    () => userSearch(input),
    {
      suspense: false,
      ...options,
    }
  );
};

/* Staff for a school */
export const staffSchema = z.object({
  userId: zh.uuid(),
  firstName: zh.string().nullish(),
  lastName: zh.string().nullish(),
  email: zh.string().nullish(),
  isDeleted: z.boolean().nullish(),
});

export type Staff = z.infer<typeof staffSchema>;

const staffOfSchoolSearch = (input: SearchInput<Staff>) => {
  return axios
    .post<SearchResult<Staff>>(
      `${baseApiPath}/search/staff-detail/typeahead`,
      input
    )
    .then((res) => res.data);
};

export const useStaffOfSchoolSearch = (
  input: SearchInput<Staff>,
  options?: QueryOptions
) => {
  return useQuery<SearchResult<Staff>, Error>(
    [baseQueryKey, 'search', input],
    () => staffOfSchoolSearch(input),
    {
      suspense: false,
      ...options,
    }
  );
};

export const useStaffOfSchoolOptions = (input: SearchInput<Staff>) => {
  const result = useStaffOfSchoolSearch(input);
  return convertDataToOptions(result.data?.data, {
    labelKey: (row) => {
      return row?.firstName || row?.lastName
        ? `${row?.firstName} ${row?.lastName}`
        : '(No name)';
    },
    valueKey: 'userId',
    sort: true,
  });
};
