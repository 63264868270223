import { queryClient } from '@@api/ApiProvider';
import {
  createAuthRefreshInterceptor,
  LoginInput,
  useLogin,
  useLogout,
} from '@@api/authApi';
import { School } from '@@api/schoolsApi';
import { fetchCurrentUserProfile } from '@@api/userProfilesApi';
import { User } from '@@api/usersApi';
import { useConfirmation } from '@@dialogs/confirmation';
import _ from 'lodash';
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { getAllowedRolesForPermission } from './helpers/getAllowedRolesForPermission';
import { PermissionsEnum } from './types/permissionsEnum';

export type UserSchool = Pick<School, 'id' | 'name'>;

type AuthContextType = {
  user: User | null;
  userIsCaregiver: boolean;
  school: UserSchool | null;
  setSchool: (school: UserSchool) => void;
  login: (data: LoginInput) => any;
  logout: () => any;
  hasPermission: (permission?: PermissionsEnum) => boolean;
};

/**
 * Context for tracking the user's authentication status and providing
 * methods for logging in and out.
 */
const AuthContext = React.createContext({} as AuthContextType);

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [authState, setAuthState] = React.useState<
    'loading' | 'resolved' | 'error'
  >('loading');
  const [user, setUser] = React.useState<User | null>(null);
  const confirmation = useConfirmation();
  const [apiLoginState, apiLogin] = useLogin();
  const [apiLogoutState, apiLogout] = useLogout();

  const [school, setSchool, { removeItem: removeSchool }] =
    useLocalStorageState<UserSchool | null>('demo/user/location', {
      defaultValue: null,
    });

  const login = (data: LoginInput) => {
    return apiLogin({ data }).then((result) => {
      removeSchool();
      setUser(result.data);

      // Set default school for staff
      if (result.data.isStaff) {
        const mainSchool = result.data.staffDetail?.mainSchool;
        if (mainSchool) {
          setSchool({
            id: mainSchool?.id,
            name: mainSchool?.name,
          });
        }
      }
    });
  };

  const logout = () => {
    return apiLogout().then(() => {
      setUser(null);
      removeSchool();
      queryClient.clear();
    });
  };

  const hasPermission = (permission?: PermissionsEnum | null) => {
    // If no permission is specified, assume the user can perform the action.
    if (!permission) return true;

    // If the user has no staff roles, they cannot perform the action.
    if (!user?.roles) return false;

    const userRoleIds = _.map(user.roles, 'id');
    const permissionRoleIds = getAllowedRolesForPermission(permission);
    const performingRoles = _.intersection(
      userRoleIds,
      permissionRoleIds
    ).length;

    return performingRoles >= 1;
  };

  // Handle logouts by the server (or token expiration)
  React.useEffect(() => {
    createAuthRefreshInterceptor(() => {
      // If the refresh token is expired, log the user out
      setUser((prevUser) => {
        if (prevUser) {
          confirmation.open({
            title: 'Logged out',
            message:
              "Your session has expired. To keep your account safe, we've logged you out. Please log back in.",

            confirmLabel: 'Ok',
          });
          queryClient.clear();
        }
        return null;
      });
    });
  }, []);

  // Check user profile and determine auth state
  const fetchUserProfile = async () => {
    try {
      const result = await fetchCurrentUserProfile();
      setUser(result);
      setAuthState('resolved');
    } catch (error) {
      setAuthState('error');
    }
  };

  // Check user profile on mount
  React.useEffect(() => {
    fetchUserProfile();
  }, []);

  // Refetch user profile every 60 seconds
  React.useEffect(() => {
    const intervalSeconds = 60;
    const interval = setInterval(() => {
      if (user) {
        fetchUserProfile();
      }
    }, 1000 * intervalSeconds);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        userIsCaregiver: !!user?.caregiverDetail?.familyId,
        login,
        logout,
        school,
        setSchool,
        hasPermission,
      }}
    >
      {authState === 'loading' ? null : children}
    </AuthContext.Provider>
  );
};
